<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title theme-gradient">
                Minor Project: Microlearning
              </h2>
              <p>Module 4 Assignment</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Portfolio Details Area  -->
    <div class="rn-portfolio-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="row row--35 mb--50">
                <div class="col-lg-12">
                  <div class="inner">
                    <div class="section-title">
                      <h2 class="heading-title">Microlearning with Next.js</h2>
                      <div
                        class="description mt--30"
                        style="text-align: justify !important"
                      >
                        <h4>Introduction</h4>
                        <p>
                          As part of <strong>LDTE 5210</strong>, I was assigned
                          a project to engage in microlearning to acquire a new
                          skill. Reflecting on my learning philosophy—which
                          emphasizes practical, hands-on experiences,
                          accessibility, and alignment with real-world
                          applications—I chose to learn
                          <strong>Next.js</strong>, a React framework for
                          building modern web applications. This assignment
                          provided an ideal opportunity to apply my educational
                          beliefs to a structured learning experience and to
                          explore how microlearning can be effectively used for
                          personal skill development.
                        </p>
                        <h4>Why I Selected Next.js</h4>
                        <h5>Professional Relevance</h5>
                        <ul>
                          <li>
                            <strong>Educational Enhancement</strong>: As an
                            instructor of Computer Science and Web and User
                            Experience at Weber State University, mastering
                            Next.js would enable me to update my curriculum with
                            current industry practices, benefiting my students.
                          </li>
                          <li>
                            <strong>Curriculum Development</strong>: Learning
                            Next.js aligns with my commitment to balancing
                            technical skills with design principles, a core
                            aspect of the Web and User Experience program I
                            helped develop.
                          </li>
                        </ul>
                        <h5>Personal Interest</h5>
                        <ul>
                          <li>
                            <strong>Curiosity-Driven Learning</strong>: My
                            passion for understanding how things work by
                            deconstructing and reconstructing them made Next.js
                            an appealing challenge.
                          </li>
                          <li>
                            <strong>Skill Advancement</strong>: Acquiring
                            proficiency in Next.js would allow me to create more
                            efficient and accessible web applications for
                            personal projects.
                          </li>
                        </ul>
                        <h4>Resources Used</h4>
                        <p>
                          To support my microlearning journey, I identified
                          resources that catered to my visual and hands-on
                          learning style:
                        </p>
                        <ul>
                          <li>
                            <p style="margin-bottom: 0 !important">
                              <strong>Official Next.js Documentation</strong>
                            </p>
                            <ul>
                              <li>
                                Provided comprehensive guides and examples for
                                self-paced learning.
                              </li>
                              <li>
                                <a href="https://nextjs.org/docs"
                                  >Next.js Docs</a
                                >
                              </li>
                            </ul>
                          </li>
                          <li>
                            <p style="margin-bottom: 0 !important">
                              <strong
                                >Next.js Crash Course by Codevolution</strong
                              >
                            </p>
                            <ul>
                              <li>
                                Offered a visual and structured introduction to
                                Next.js concepts.
                              </li>
                              <li>
                                <a
                                  href="https://www.youtube.com/watch?v=ZjAqacIC_3c&list=PLC3y8-rFHvwjOKd6gdf4QtV1uYNiQnruI"
                                  >YouTube Link</a
                                >
                              </li>
                            </ul>
                          </li>
                          <li>
                            <p style="margin-bottom: 0 !important">
                              <strong
                                >Accessibility Guidelines and Resources</strong
                              >
                            </p>
                            <ul>
                              <li>
                                Ensured that my learning and projects adhered to
                                inclusive design principles, reflecting my
                                educational philosophy.
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <h4>Planning the Activities</h4>
                        <p>
                          To align with microlearning principles—delivering
                          information in small, focused chunks—I structured my
                          learning over <strong>two weeks</strong>, dedicating
                          <strong>30 minutes daily</strong> to specific topics.
                        </p>
                        <h5>Activity Schedule</h5>
                        <ul>
                          <li>
                            <strong>Week 1: Fundamentals of Next.js</strong>
                            <ul>
                              <li>
                                <strong>Day 1</strong>: Setting up the
                                development environment and exploring the
                                starter app.
                              </li>
                              <li>
                                <strong>Day 2</strong>: Understanding file-based
                                routing and creating basic pages.
                              </li>
                              <li>
                                <strong>Day 3</strong>: Implementing CSS styling
                                and exploring design principles.
                              </li>
                              <li>
                                <strong>Day 4</strong>: Managing assets,
                                metadata, and static files.
                              </li>
                              <li>
                                <strong>Day 5</strong>: Learning data fetching
                                methods (<code>getStaticProps</code>,
                                <code>getServerSideProps</code>).
                              </li>
                              <li>
                                <strong>Day 6</strong>: Building a simple blog
                                application to apply learned concepts.
                              </li>
                              <li>
                                <strong>Day 7</strong>: Reviewing the week's
                                material and conducting formative assessment.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <strong>Week 2: Advanced Features</strong>
                            <ul>
                              <li>
                                <strong>Day 8</strong>: Implementing dynamic
                                routing and catch-all routes.
                              </li>
                              <li>
                                <strong>Day 9</strong>: Creating API routes and
                                integrating backend functionality.
                              </li>
                              <li>
                                <strong>Day 10</strong>: Adding authentication
                                mechanisms and focusing on security.
                              </li>
                              <li>
                                <strong>Day 11</strong>: Customizing middleware
                                and server configurations for optimization.
                              </li>
                              <li>
                                <strong>Day 12</strong>: Enhancing performance
                                with code splitting and prefetching.
                              </li>
                              <li>
                                <strong>Day 13</strong>: Deploying the
                                application to Vercel and understanding
                                deployment processes.
                              </li>
                              <li>
                                <strong>Day 14</strong>: Conducting summative
                                assessment and reflecting on the learning
                                experience.
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <h5>Time Allocation</h5>
                        <ul>
                          <li><strong>Total Duration</strong>: 14 days</li>
                          <li><strong>Daily Commitment</strong>: 30 minutes</li>
                          <li><strong>Total Hours</strong>: 7 hours</li>
                        </ul>
                        <h4>
                          Activities for Skill Acquisition and Development
                        </h4>
                        <p style="margin-bottom: 0 !important">
                          To ensure effective learning, I engaged in activities
                          that promoted active participation and immediate
                          application:
                        </p>
                        <ul>
                          <li>
                            <strong>Hands-On Coding</strong>: Each session
                            included practical exercises where I wrote and
                            modified code based on the day's topic.
                          </li>
                          <li>
                            <strong>Project Development</strong>: Applied
                            concepts by progressively building a blog
                            application, reinforcing learning through creation.
                          </li>
                          <li>
                            <strong>Accessibility Implementation</strong>:
                            Integrated accessibility features into the
                            application from the outset, aligning with my
                            commitment to inclusive design.
                          </li>
                        </ul>
                        <h4>Assessment Metrics</h4>
                        <h5>Formative Assessment</h5>
                        <ul>
                          <li>
                            <strong
                              >Daily Reflections and Journal Entries</strong
                            >
                            <ul>
                              <li>
                                Documented challenges, insights, and questions
                                after each session.
                              </li>
                              <li>
                                Reflected on how new knowledge connected with
                                prior learning and teaching practices.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <strong>Self-Quizzes</strong>
                            <ul>
                              <li>
                                Created short quizzes based on the day's
                                material to test recall and understanding.
                              </li>
                              <li>
                                Used online resources to check answers and fill
                                knowledge gaps.
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <h5>Summative Assessment</h5>
                        <ul>
                          <li>
                            <strong>Final Project Evaluation</strong>
                            <ul>
                              <li>
                                Assessed the completed blog application against
                                specific criteria:
                                <ul>
                                  <li>
                                    <strong>Functionality</strong>: Does the
                                    application work as intended?
                                  </li>
                                  <li>
                                    <strong>Code Quality</strong>: Is the code
                                    clean, well-organized, and follows best
                                    practices?
                                  </li>
                                  <li>
                                    <strong>Accessibility</strong>: Does the
                                    application meet accessibility standards?
                                  </li>
                                  <li>
                                    <strong>Design Principles</strong>: Is the
                                    user interface intuitive and visually
                                    appealing?
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <strong>Peer Review</strong>
                            <ul>
                              <li>
                                Shared the project with colleagues and received
                                feedback on usability, design, and educational
                                value.
                              </li>
                              <li>
                                Incorporated suggestions to improve the
                                application and my understanding.
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <h4>Self-Reflection</h4>
                        <h5>Personal Reactions</h5>
                        <p>
                          Engaging in this microlearning assignment was both
                          challenging and rewarding. The structured approach
                          made the learning process manageable, and focusing on
                          small, daily goals kept me motivated.
                        </p>
                        <h5>Lessons Learned</h5>
                        <ul>
                          <li>
                            <strong>Effectiveness of Microlearning</strong>
                            <ul>
                              <li>
                                Breaking down learning into focused sessions
                                enhanced retention and made complex topics
                                approachable.
                              </li>
                              <li>
                                The format is conducive to busy schedules,
                                allowing for consistent progress without
                                overwhelming time commitments.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <strong>Importance of Hands-On Practice</strong>
                            <ul>
                              <li>
                                Active participation through coding reinforced
                                concepts better than passive learning methods.
                              </li>
                              <li>
                                Building a project provided a tangible outcome
                                that demonstrated skill acquisition.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <strong>Value of Reflection</strong>
                            <ul>
                              <li>
                                Daily journaling helped solidify learning and
                                identify areas needing further exploration.
                              </li>
                              <li>
                                Reflecting on how new knowledge integrates with
                                teaching practices enriched the experience.
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <h5>What Made It Effective</h5>
                        <ul>
                          <li>
                            <strong>Alignment with Learning Philosophy</strong>
                            <ul>
                              <li>
                                The hands-on, practical approach mirrored my
                                educational beliefs, enhancing engagement.
                              </li>
                              <li>
                                Emphasizing accessibility ensured the project
                                was inclusive and applicable to my teaching.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <strong>Resource Selection</strong>
                            <ul>
                              <li>
                                Utilizing a mix of documentation, tutorials, and
                                interactive platforms catered to different
                                learning preferences.
                              </li>
                              <li>
                                Resources were chosen for their clarity,
                                relevance, and ability to facilitate
                                microlearning.
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <h5>What I Might Do Differently</h5>
                        <ul>
                          <li>
                            <strong
                              >Allocate More Time for Complex Topics</strong
                            >
                            <ul>
                              <li>
                                Some advanced features could have benefited from
                                longer or additional sessions.
                              </li>
                              <li>
                                In future learning, I might adjust the schedule
                                to allow more in-depth exploration when needed.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <strong>Increase Collaboration Early On</strong>
                            <ul>
                              <li>
                                Involving peers or students earlier could
                                provide diverse perspectives and enhance
                                understanding.
                              </li>
                              <li>
                                Collaborative learning could also simulate
                                real-world development environments.
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <h5>Replicating the Experience with Others</h5>
                        <ul>
                          <li>
                            <strong>Designing Student Assignments</strong>
                            <ul>
                              <li>
                                Develop microlearning modules for students,
                                incorporating daily goals and hands-on
                                activities.
                              </li>
                              <li>
                                Emphasize the importance of reflection and
                                self-assessment in their learning process.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <strong>Creating Learning Communities</strong>
                            <ul>
                              <li>
                                Encourage the formation of study groups where
                                students can share progress and challenges.
                              </li>
                              <li>
                                Facilitate peer reviews to enhance collaborative
                                skills and collective knowledge.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <strong
                              >Integrating Microlearning into Curriculum</strong
                            >
                            <ul>
                              <li>
                                Apply microlearning principles to course design,
                                breaking down content into focused, manageable
                                segments.
                              </li>
                              <li>
                                Use microlearning for professional development
                                among faculty to foster continuous learning.
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <h4>Conclusion</h4>
                        <p>
                          This microlearning assignment allowed me to acquire a
                          valuable new skill while applying my learning
                          philosophy in a structured context. By focusing on
                          small, manageable learning units, I made consistent
                          progress without feeling overwhelmed. The experience
                          demonstrated the effectiveness of microlearning as a
                          strategy for ongoing professional development and
                          reinforced my commitment to practical, hands-on
                          education. Moving forward, I plan to incorporate
                          microlearning principles into my teaching to enhance
                          student engagement, accessibility, and real-world
                          application of skills.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area  -->

    <Footer />
  </div>
</template>

<script>
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/FooterTwo";
import feather from "feather-icons";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      logo: require("../../assets/images/logo/logo.png"),
      items: [
        {
          thumb: require("../../assets/images/portfolio/portfolio-big-03.jpg"),
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
        },
      ],
      listOne: [
        {
          id: 1,
          icon: "check",
          desc: `5 PPC Campaigns the majority.`,
        },
        {
          id: 2,
          icon: "check",
          desc: `Digital Marketing going to.`,
        },
        {
          id: 3,
          icon: "check",
          desc: ` Marketing Agency passage of.`,
        },
        {
          id: 4,
          icon: "check",
          desc: ` Seo Friendly you are going.`,
        },
      ],
      socialList: [
        {
          url: "https://www.facebook.com/",
          icon: "facebook",
        },
        {
          url: "https://www.linkedin.com/",
          icon: "linkedin",
        },
        {
          url: "https://instagram.com/",
          icon: "instagram",
        },
        {
          url: "https://twitter.com/",
          icon: "twitter",
        },
      ],
      moreProjects: [
        {
          src: require("../../assets/images/portfolio/related-image-01.jpg"),
          title: "Digital Analysis",
          categorie: "Technique",
        },
        {
          src: require("../../assets/images/portfolio/related-image-02.jpg"),
          title: "Plan Management",
          categorie: "Planning",
        },
      ],
      index: null,
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>

<style></style>
